//=================================================
// Card
//=================================================

/*=============================
// Card post
=============================*/
.card-post {
    background-color: $body_background_color;
    border: 0;
    height: 100%;
    
    // Card post img
    .img {
        opacity: 0.34;
        @extend .transition-opacity;
        
        &:hover {
            opacity: 1;
            @extend .transition-opacity;
        }
        
        &.object-fit {
            &:before {
                padding-top: 95%;
            }
        }
    }
    
    // Card post category
    .card-category {
        font-family: $font_secondary_bold;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 0 2.25rem 2.25rem;
        
        a {
            font-family: $font_secondary_bold;
            margin-right: 1.125rem;
            
            &:last-child {
                margin-right: 0;
            }
            
            &.btn {
                background-color: $white;
                border-color: $white;
                
                &:hover {
                    background-color: $white;
                    border-color: $white;
                }
            }
        }
        
        &.no-thumbnail {
            position: relative;
            padding: 1.14rem 0.7rem 1.14rem .7rem;
        }
    }
    
    // Card post body
    .card-body {
        padding: 2.25rem;
                
        // Card post title
        .card-title {
            margin-bottom: 0;
            
            a {
                display: block;
                
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    
    // Is sticky
    &.sticky {
        box-shadow: -10px 20px 40px 0 rgba(0, 0, 0, 0.06);
    }
}

// Card body padding
.card-body {
    &.p-large {
        padding: 3.13rem 4.38rem;
        
        @media #{$media_xxl} {
            padding: 2.19rem 3.44rem;
        }
    }
}

// Card height
.card {
    height: 100%;
    
    h1, h2, h3, h4, h5, h6 {
        &:hover {
            opacity: 0.8;
        }
    }
}

/*=============================
// Card hover
=============================*/
.card-hover {
    // Efect 1
    &-ef1 {
        &:hover {
            opacity: 1;
            
            .card-hover-content {
                &:before {
                    transform: translateX(0);
                    visibility: visible;
                }
                
                .card-hover-wrapper {
                    opacity: 1;
                } 
            }
        }
    }
}

.card-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: all .5s ease-in-out;
    overflow: hidden;
    
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(44,44,44,.96);
        visibility: hidden;
        transform: translateX(-100%);
        transition: all .5s ease-in-out;
    }
    
    .card-hover-wrapper {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 3.75rem;
        opacity: 0;
        transition: all .5s ease-in-out;
    }
}
