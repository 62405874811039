//=================================================
// Fonts
//=================================================

// Lora-Regular
@font-face {
    font-family: 'Lora-Regular';
    src: url('../fonts/Lora/Lora-Regular/Lora-Regular.eot');
    src: url('../fonts/Lora/Lora-Regular/Lora-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lora/Lora-Regular/Lora-Regular.woff2') format('woff2'),
         url('../fonts/Lora/Lora-Regular/Lora-Regular.woff') format('woff'), 
         url('../fonts/Lora/Lora-Regular/Lora-Regular.ttf') format('truetype'),
         url('../fonts/Lora/Lora-Regular/Lora-Regular.svg#Lora-Regular') format('svg');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

// Lora-Bold
@font-face {
    font-family: 'Lora-Bold';
    src: url('../fonts/Lora/Lora-Bold/Lora-Bold.eot');
    src: url('../fonts/Lora/Lora-Bold/Lora-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lora/Lora-Bold/Lora-Bold.woff2') format('woff2'),
         url('../fonts/Lora/Lora-Bold/Lora-Bold.woff') format('woff'), 
         url('../fonts/Lora/Lora-Bold/Lora-Bold.ttf') format('truetype'),
         url('../fonts/Lora/Lora-Bold/Lora-Bold.svg#Lora-Bold') format('svg');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

// Ubuntu-Regular
@font-face {
    font-family: 'Ubuntu-Regular';
    src: url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.woff2') format('woff2'),
         url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.woff') format('woff'), 
         url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.ttf') format('truetype'),
         url('../fonts/Ubuntu/Ubuntu-Regular/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

// Ubuntu-Bold
@font-face {
    font-family: 'Ubuntu-Bold';
    src: url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.woff2') format('woff2'),
         url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.woff') format('woff'), 
         url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.ttf') format('truetype'),
         url('../fonts/Ubuntu/Ubuntu-Bold/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}
