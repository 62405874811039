//=================================================
// Buttons
//=================================================

.btn {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 0.81rem;
    padding: 1.56rem 4.75rem;
    line-height: 1.23;
    @include lhCrop-b(1.23, 0.75);
    @include lhCrop-a(1.23, 0.83);


    &.btn-sm {
        padding: 0.81rem 1.69rem;
    }

    &.btn-lg {
        padding: 1.75rem 5.63rem;
    }
    
    &.btn-white {
        background-color: $white;
        border-color: $white;
    }
        
    &.btn-vertical {
        .vertical-text {
            span {
                display: block;
            }
        }
    }
    
    &.btn-light {
        color: $body_links_color;
        
        &:hover {
            color: $body_links_color;
        }
    }
    
    // Weight
    &.btn-bold {
        font-family: $font_secondary_bold;
    }
}

// Block button
.has-text-color {
    &.has-white-color {
        color: $white !important;
    }
    
    &.has-gray-color {
        color: $gray !important;
    }
    
    &.has-gray-light-color {
        color: $gray-light !important;
    }
    
    &.has-gray-dark-color {
        color: $gray-dark !important;
    }
    
    &.has-blue-color {
        color: $blue !important;
    }
    
    &.has-indigo-color {
        color: $indigo !important;
    }
    
    &.has-purple-color {
        color: $purple !important;
    }
    
    &.has-pink-color {
        color: $pink !important;
    }
    
    &.has-red-color {
        color: $red !important;
    }
    
    &.has-orange-color {
        color: $orange !important;
    }
    
    &.has-yellow-color {
        color: $yellow !important;
    }
    
    &.has-green-color {
        color: $green !important;
    }
    
    &.has-teal-color {
        color: $teal !important;
    }
    
    &.has-cyan-color {
        color: $cyan !important;
    }
}

.has-background {
    &.has-white-background-color {
        background-color: $white !important;
    }
    
    &.has-gray-background-color {
        background-color: $gray !important;
    }
    
    &.has-gray-light-background-color {
        background-color: $gray-light !important;
    }
    
    &.has-gray-dark-background-color {
        background-color: $gray-dark !important;
    }
    
    &.has-blue-background-color {
        background-color: $blue !important;
    }
    
    &.has-indigo-background-color {
        background-color: $indigo !important;
    }
    
    &.has-purple-background-color {
        background-color: $purple !important;
    }
    
    &.has-pink-background-color {
        background-color: $pink !important;
    }
    
    &.has-red-background-color {
        background-color: $red !important;
    }
    
    &.has-orange-background-color {
        background-color: $orange !important;
    }
    
    &.has-yellow-background-color {
        background-color: $yellow !important;
    }
    
    &.has-green-background-color {
        background-color: $green !important;
    }
    
    &.has-teal-background-color {
        background-color: $teal !important;
    }
    
    &.has-cyan-background-color {
        background-color: $cyan !important;
    }
}

// Default
button {
    &:focus {
        outline: 0;
    }
}
