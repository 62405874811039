//=================================================
// Main
//=================================================

/*=============================
// All page
=============================*/
main {
    padding-top: $main_padding_t;
    padding-bottom: $main_padding_b;
}

/*=============================
// Home page
=============================*/
.home {
    main {
        padding-bottom: 0;
    }
}

/*=============================
// Home blog page
=============================*/
.home {
    &.blog {
        main {
            padding-bottom: $main_padding_b;
        }
    }
}
