//=================================================
// Page title
//=================================================

#page-title {
    @media #{$media_md} {
        .w-75 {
            width: 100% !important;
        }
    }
    
    .img {
        &.object-fit {
            &:before {
                padding-top: 113.8444%;
            }
        }
    }

    .row, [class*="-row"] {
        > .col:first-child {
            padding-right: 0;
            
            @media #{$media_sm} {
                padding-right: 15px;
            }
        }
                
        > .col:last-child {
            padding-left: 0;
        }
        
        .col-1 {
            -ms-flex: 0 0 60.1555%;
            flex: 0 0 60.1555%;
            max-width: 60.1555%;
        }
        
        .col-2 {
            -ms-flex: 0 0 39.8445%;
            flex: 0 0 39.8445%;
            max-width: 39.8445%;
        }
        
        @media #{$media_sm} {
            .col {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }  
    }
    
    .p-left-scroll {
        margin-left: 17.5%;
        margin-bottom: -0.1rem;
        width: auto;
        
        @media #{$media_lg} {
            display: none;
        }
    }
    
    [class*=-element] [class*=-row] {
        flex-wrap: nowrap;
    }
}

.title {
    h1 {
        margin-bottom: 0;
        @include lhCrop-a(1.15, 0.75);
            
        &.small {
            @include lhCrop-a(1.21, 0.735);
        }
    }
        
    .title-clone {
        font-family: $font_secondary_bold;
        color: $body_text_clone_color;
        position: absolute;
        right: -22px;
        bottom: -95px;
        z-index: -1;
        line-height: 77%;
        font-size: 31.25rem;
             
        @media #{$media_xxl} {
            font-size: 29.411764705882355vw;
        }
            
        @media #{$media_sm} {
            right: 0;
            bottom: -35px;
            font-size: 12.5rem;
        }
                
        &.left {
            left: 0;
            right: 0;
        }
    }
}

/*=============================
// Home page
=============================*/
.home:not(.blog)  {
    #page-title {
        .title {
            .title-clone {
                bottom: 0;
            }
        }
    }
}

/*=============================
// Search results
=============================*/
.search-results {
     #page-title {
        .title {
            .title-clone {
                top: -76px;
                bottom: inherit;
            }
         }
    }
}
