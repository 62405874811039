//=================================================
// Block
//=================================================

.block {
    h2 {
        padding-bottom: $space_lg;
        margin-bottom: 0;
        @include lhCrop-a(1.11, 0.75);
    }
}
