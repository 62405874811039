//=================================================
// Comments
//=================================================

#comments {
    // Title
    .comment-title {
        margin-left: -0.63rem;
    }
    
    // Comment list
    .comment-list {
        margin-bottom: 0;

        .comment {
            margin-bottom: $space_lg;
        
            &:before, &:after {
                display: none;
            }
            
            &:last-child {
                margin-bottom: 0;
            }
            
            .comment-header {
                margin-bottom: $space_xs;
                
                .comment-author {
                    a {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
            
            .comment-content {
                h1 {       
                    font-size: 3.88rem;
                }
                
                h2 {
                    padding-bottom: 0;
                }
                
                h1, h2, h3, h4, h5, h6 {
                    line-height: 1.56;
                    @include lhCrop-b(1.56, 0.75);
                    margin-bottom: 1.01rem;
    
                    &:last-child {
                        @include lhCrop-a(1.56, 0.75);
                    }
                }
                
                table, ol, ul {
                    margin-bottom: $space_sm;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                
                ul {
                    li {
                        &:before {
                            content: "\2022";
                        }
                    }
                }
            }
            
            .children {
                margin-left: 9.2rem;
                
                @media #{$media_xxl} {
                    margin-left: 5.5rem;
                }
                
                .comment {
                    margin-top: $space_lg;
                }
            }
        }
            
        // Comment respond
        #respond {
            margin-top: $space_lg;
            margin-bottom: $space_lg;
        }
    }
    
    // Comment respond
    #respond {
        .comment-form {
            .comment-notes, .logged-in-as {
                @include lhCrop-b(1.75, 0.75);
                @include lhCrop-a(1.75, 0.75);
            }
            
            .comment-form-cookies-consent {
                label {
                    margin-bottom: 0;
                }
            }
            
            .form-submit {
                margin-bottom: 0;
            }
            
            p {
                a {
                    font-weight: normal;
                }
            }
        }
    }
}
