//=================================================
// Icons
//=================================================

[class^="icon-"], [class*=" icon-"] {
    position: relative;
    top: -0.06rem;
    
    @media #{$media_xl} {
        position: static;
        top: auto;
    }
    
    &:before {
        margin: 0;   
    }
}

/*=============================
// Header
=============================*/
header {
    .icon-c {
        // Menu icon
        &.icon-menu {
            font-size: 0.5rem;
            margin-left: 0.94rem;
        }
        
        // Close icon
        &.icon-close {
            font-size: 0.5rem;
            margin-left: 0.94rem
        }
    }
}

/*=============================
// Modal
=============================*/
.modal {
    .icon-c {
        // Close icon
        &.icon-close {
            font-size: 0.5rem;
            margin-left: 0.94rem
        }
    }
}

/*=============================
// Page title
=============================*/
#page-title {
    .icon-c {
        &.icon-arrow-down {
            font-size: 0.63rem;
            margin-left: 0.63rem;
            
            &:before {
                width: 0.38rem;
            }
        }
    }
}

/*=============================
// Button
=============================*/
.btn {
    .icon-c {
        // Arrow left
        &.icon-arrow-left {
            margin-right: 0.94rem;
            font-size: 0.63rem;
        }
    
        // Arrow right
        &.icon-arrow-right {
            margin-left: 0.94rem;
            font-size: 0.63rem;
        }
    }
}

/*=============================
// Widget
=============================*/
.widget {
    .icon-c {
        // Search
        &.icon-arrow-right {
            cursor: pointer;
            font-size: 0.94rem;
            @extend .transition-all;
            
            &:hover {
                opacity: 0.8;
                @extend .transition-all;
            }
        }
    }
}

/*=============================
// Menu secondary
=============================*/
.menu-primary {
    .icon-c {
        &.icon-arrow-down {
            cursor: pointer;
            font-size: 1.56rem;
        }
    }
}

/*=============================
// Menu secondary
=============================*/
.menu-secondary {
    .icon-c {
        &.icon-arrow-down {
            cursor: pointer;
            font-size: 0.5rem;
        }
    }
}
