//=================================================
// 404
//=================================================

.error404 {
    .input-group {
        &.border-bottom {
            border-bottom: 1px solid $input_border_secondary_color !important;
        
            .form-control {
                margin-bottom: -1px;
            }
        }
    }
   
    @media #{$media_md} {
        .w-50 {
            width: 100% !important;
            max-width: 400px;
        }
        
        .button {
            .btn {
                margin-top: 3.38rem;
            }
        }  
    }
}
