//=================================================
// Mixins
//=================================================

//=============================
// Line height crop
//=============================
// Before
@mixin lhCrop-b($line-height, $capital-letter: 1) {
    &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
    }
}

// After
@mixin lhCrop-a($line-height, $capital-letter: 1) {
    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-bottom: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
    }
}
