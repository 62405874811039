//=================================================
// Logo
//=================================================

.logo {
    display: block;
    
    img {
        max-height: 2.63rem;  
    }
}
